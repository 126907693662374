import React from 'react'
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBIcon,
  MDBCard,
  MDBCardTitle,
  MDBCardImage,
  MDBCardText,
  MDBAnimation,
  MDBNavLink,
} from 'mdbreact'
import './HomePage.css'
import { isWalletUnlocked } from '.././blockchainUtils'

class HomePage extends React.Component {
  state = {
    isWalletUnlocked: false,
  }
  scrollToTop = () => window.scrollTo(0, 0)

  UNSAFE_componentWillMount() {
    isWalletUnlocked().then(_isWalletUnlocked => {
      this.setState({
        isWalletUnlocked: _isWalletUnlocked,
      })
    })
  }

  render() {
    return (
      <>
        <MDBEdgeHeader color="indigo darken-3" className="sectionPage" />
        <div className="mt-3 mb-5">
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md="10"
                className="mx-auto float-none white z-depth-1 py-2 px-2"
              >
                <MDBCardBody className="text-center">
                  <h2 className="h2-responsive mb-4">
                    <strong className="font-weight-bold">ART ID</strong>
                  </h2>
                  <p>Protect your art using Blockchain technology</p>
                  <MDBRow className="p-1 d-flex flex-row justify-content-center row">
                    <MDBNavLink
                      tag="link"
                      link={true}
                      to={this.state.isWalletUnlocked ? '/protect' : '/sign-in'}
                      color="mdb-color"
                      style={{ padding: '20px', width: '300px' }}
                      className="border nav-link border-light rounded mr-1 mx-2 mb-2"
                      onClick={this.scrollToTop}
                    >
                      <MDBIcon far icon="gem" className="mr-2" />
                      <span className="font-weight-bold">
                        Art Property Management
                      </span>
                    </MDBNavLink>
                  </MDBRow>
                  <MDBRow className="p-1 d-flex flex-row justify-content-center row">
                    <a
                      style={{ padding: '20px', width: '300px' }}
                      className="border nav-link border-light rounded mx-2 mb-2 disabled"
                      href="#/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon icon="graduation-cap" className="mr-2" />
                      <span className="font-weight-bold">Match my Art</span>
                    </a>
                  </MDBRow>
                  <MDBRow className="p-1 d-flex flex-row justify-content-center row">
                    <a
                      style={{ padding: '20px', width: '300px' }}
                      className="border nav-link border-light rounded mx-2 mb-2 disabled"
                      href="https://becomart.ch"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MDBIcon icon="download" className="mr-2" />
                      <span className="font-weight-bold">Media Agency</span>
                    </a>
                  </MDBRow>
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12" className="mt-4">
                <h2 className="text-center my-5 font-weight-bold">
                  Why is it so great?
                </h2>
                <p className="text-center text-muted mb-1">
                  Using Blockchain technology is a game changer in the art
                  industry... There is more to say here... We have proven our
                  expertise to respond effectively and rigorously to your needs
                  and expectations through our artistic profiles and
                  sensibilities, and our many professional as well as cultural
                  experiences.
                </p>

                <MDBRow id="categories">
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInLeft">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="http://becomart.ch/wp-content/uploads/2017/10/COMMUNICATION-GLOBALE-495x400.jpg"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <MDBIcon
                              icon="css3"
                              brand
                              className="pink-text pr-2"
                            />
                            <strong>COMMUNICATION</strong>
                          </MDBCardTitle>
                          <MDBCardText>
                            To communicate effectively today is a crucial
                            challenge for every business. The essential issue is
                            how to develop a comprehensive and coherent strategy
                            and to put in place a precise and targeted action
                            plan in order to respond to key issues and to convey
                            the company’s image in accordance with its core
                            values and objectives.
                          </MDBCardText>
                          <MDBNavLink
                            tag="button"
                            to="/protect"
                            color="mdb-color"
                            className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                            onClick={this.scrollToTop}
                          >
                            More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInDown">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="http://becomart.ch/wp-content/uploads/2017/10/EVENEMENTS-SUR-MESURE-495x400.jpg"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <MDBIcon icon="cubes" className="blue-text pr-2" />
                            <strong>EVENTS & FESTIVALS</strong>
                          </MDBCardTitle>
                          <MDBCardText>
                            The organization of events of any size, in the
                            sports, cultural, private or corporate domain is a
                            complex machinery. Each of the different phases,
                            from the conception of the event to its realization,
                            while also crafting its promotion and organization,
                            require specific expertise.
                          </MDBCardText>
                          <MDBNavLink
                            tag="button"
                            to="/protect"
                            color="mdb-color"
                            className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                            onClick={this.scrollToTop}
                          >
                            More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                  <MDBCol md="4">
                    <MDBAnimation reveal type="fadeInRight">
                      <MDBCard cascade className="my-3 grey lighten-4">
                        <MDBCardImage
                          cascade
                          className="img-fluid"
                          src="http://becomart.ch/wp-content/uploads/2017/10/CULTURAL-MEDIATION-495x400.jpg"
                        />
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <MDBIcon icon="code" className="green-text pr-2" />
                            <strong>CULTURAL MEDIATION</strong>
                          </MDBCardTitle>
                          <MDBCardText>
                            aking culture and art accessible to a wider
                            population, raising awareness, engaging and
                            involving people are major missions and objectives
                            of cultural mediation. Mediation develops the
                            criticism and creativity of the public, arouses its
                            curiosity and interest and allows to create links
                            and opportunities of exchanges and encounters
                            between the population and the artistic circles.
                          </MDBCardText>

                          <MDBNavLink
                            tag="button"
                            to="/protect"
                            color="mdb-color"
                            className="btn btn-outline-mdb-color btn-sm btn-rounded d-inline"
                            onClick={this.scrollToTop}
                          >
                            More
                          </MDBNavLink>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBAnimation>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </>
    )
  }
}

export default HomePage
