import React, { Component } from 'react'
import {
  MDBBtn,
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBRow,
} from 'mdbreact'
import Web3 from 'web3'
import { Redirect, withRouter } from 'react-router-dom'
import { getArtWork, saveArtWork } from '../blockchainUtils'

class PieceOfArt extends Component {
  state = {
    collapseID: 'accordion1',
    loading: true,
    account: '',
    redirect: '',
    artWork: {
      metadata: {},
    },
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.loadWeb3()
    this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      // DO NOTHING...
    }
  }

  async loadBlockchainData() {
    const { params } = this.props.match

    this.setState({ loading: true })

    if (params.id) {
      const artWork = await getArtWork(params.id)
      if (artWork) {
        artWork.metadata = JSON.parse(artWork.metadata)
        this.setState({
          artWork: artWork,
          loading: false,
        })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  async handleSubmit(e) {
    e.preventDefault()

    this.setState({ loading: true })

    const data = new FormData(e.target)
    const artWork = {
      name: data.get('name'),
      metadata: JSON.stringify({
        description: data.get('description'),
      }),
    }

    if (this.state.artWork.id) {
      artWork.id = this.state.artWork.id
    }
    await saveArtWork(artWork)
    this.setState({
      // loading: false,
      // redirect: '/protect'
    })
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }))
  }

  render() {
    const { artWork } = this.state
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const { collapseID } = this.state
    return (
      <MDBContainer>
        <form onSubmit={this.handleSubmit}>
          <MDBRow>
            <MDBCol md="6">
              <h1>Protect a new Art Work</h1>
            </MDBCol>
            <MDBCol md="6" className="text-right">
              <MDBBtn
                className="btn btn-sm btn-white"
                onClick={() => this.setState({ redirect: '/protect' })}
              >
                <MDBIcon icon="arrow-left" />
                <span> Back to list</span>
              </MDBBtn>

              <MDBBtn type="submit" className="btn btn-sm">
                <MDBIcon icon="shield-alt" />
                <span> Save data on the blockchain</span>
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          {this.state.loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <MDBRow
                onClick={this.toggleCollapse('accordion1')}
                style={{ marginTop: '2rem', cursor: 'pointer' }}
              >
                <MDBCol md="9">
                  <h5>Art Work Identification</h5>
                </MDBCol>
                <MDBCol md="3" className="text-right">
                  <MDBIcon icon="angle-down" />
                </MDBCol>
              </MDBRow>

              <hr />

              <MDBCollapse id="accordion1" isOpen={collapseID}>
                The creator of a piece of art is automatically protected by a
                copyright (moral rights). This right is inalienable according to
                the Swiss Code of Obligations, articles x y *
                <MDBContainer>
                  <MDBCol md="6">
                    <MDBInput
                      valueDefault={artWork.name}
                      name="name"
                      label="Name"
                    >
                      <small className="form-text text-muted">
                        Give your Art Work a special name.
                      </small>
                    </MDBInput>
                  </MDBCol>

                  <MDBCol md="6">
                    <MDBInput
                      valueDefault={artWork.metadata.description}
                      name="description"
                      label="Description"
                    >
                      <small className="form-text text-muted">
                        Briefly describe you Work what makes it recognizable.
                      </small>
                    </MDBInput>
                  </MDBCol>
                </MDBContainer>
              </MDBCollapse>

              <MDBRow
                onClick={this.toggleCollapse('accordion2')}
                style={{ marginTop: '2rem', cursor: 'pointer' }}
              >
                <MDBCol md="9">
                  <h5>Right of Author</h5>
                </MDBCol>
                <MDBCol md="3" className="text-right">
                  <MDBIcon icon="angle-down" />
                </MDBCol>
              </MDBRow>

              <hr />

              <MDBCollapse id="accordion2" isOpen={collapseID}>
                The right of remuneration of a piece of art can be agreed
                between third parties by contract.
              </MDBCollapse>

              <MDBRow
                onClick={this.toggleCollapse('accordion3')}
                style={{ marginTop: '2rem', cursor: 'pointer' }}
              >
                <MDBCol md="9">
                  <h5>Right of remuneration</h5>
                </MDBCol>
                <MDBCol md="3" className="text-right">
                  <MDBIcon icon="angle-down" />
                </MDBCol>
              </MDBRow>

              <hr />

              <MDBCollapse id="accordion3" isOpen={collapseID}>
                To be continued... right of Remuneration!
              </MDBCollapse>

              <MDBRow
                onClick={this.toggleCollapse('accordion4')}
                style={{ marginTop: '2rem', cursor: 'pointer' }}
              >
                <MDBCol md="9">
                  <h5>Right of Administration</h5>
                </MDBCol>
                <MDBCol md="3" className="text-right">
                  <MDBIcon icon="angle-down" />
                </MDBCol>
              </MDBRow>

              <hr />

              <MDBCollapse id="accordion4" isOpen={collapseID}>
                The right of administration authorizes to modify the piece of
                art description or grants a right of signature to establish a
                contract between third party.
              </MDBCollapse>

              <MDBRow
                onClick={this.toggleCollapse('accordion5')}
                style={{ marginTop: '2rem', cursor: 'pointer' }}
              >
                <MDBCol md="9">
                  <h5>Right of Use</h5>
                </MDBCol>
                <MDBCol md="3" className="text-right">
                  <MDBIcon icon="angle-down" />
                </MDBCol>
              </MDBRow>

              <hr />

              <MDBCollapse id="accordion5" isOpen={collapseID}>
                An contract is established between parties defining the
                condition to use the work.
              </MDBCollapse>
            </>
          )}
        </form>
      </MDBContainer>
    )
  }
}

export default withRouter(PieceOfArt)
