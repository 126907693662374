import Web3 from 'web3'
import { ART_ID_ABI, ART_ID_ADDRESS } from './config'

export const loadWeb3 = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum)
    await window.ethereum.enable()
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider)
  } else {
    // DO NOTHING...
  }
}
export const isWeb3Compatible = async () => {
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum)
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider)
  } else {
    // DO NOTHING...
  }
}

export const isWalletUnlocked = async () => {
  return (await getSenderAddress()) !== ''
}

export const saveArtWork = async artWork => {
  const web3 = window.web3

  const account = await getSenderAddress()
  const artId = new web3.eth.Contract(ART_ID_ABI, ART_ID_ADDRESS)

  if (artWork.id) {
    artId.methods
      .updateArtWork(artWork.id, artWork.name, artWork.metadata)
      .send({ from: account }, () => {
        window.location.href = '/protect'
      })
      .once('response', response => {
        // console.log(response) // no output here
      })
  } else {
    artId.methods
      .createArtWork(artWork.name, artWork.metadata)
      .send({ from: account })
      .once('response', response => {
        // console.log(response) // no output here
      })
  }
}
/**
 * @returns {Promise<[]>}
 */
export const getArtWorks = async () => {
  const web3 = window.web3

  const artId = new web3.eth.Contract(ART_ID_ABI, ART_ID_ADDRESS)
  const artWorkCount = await artId.methods.artWorkCount().call()

  // Render out each task with a new task template
  const _rows = []
  for (let i = 1; i <= artWorkCount; i++) {
    let artWork = await artId.methods.artWorks(i).call()
    _rows.push(artWork)
  }

  return _rows
}

/**
 * @returns {Promise<[]>}
 */
export const getArtWork = async id => {
  const web3 = window.web3

  const artId = new web3.eth.Contract(ART_ID_ABI, ART_ID_ADDRESS)
  return await artId.methods.artWorks(id).call()
}

/**
 * @returns string
 */
export const getSenderAddress = async () => {
  // Web3.givenProvider if Metamask is available
  const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')
  // const network = await web3.eth.net.getNetworkType()
  const accounts = await web3.eth.getAccounts()
  return accounts.length > 0 ? accounts[0] : ''
}

export const loadBlockchainData = async () => {
  // Web3.givenProvider if Metamask is available
  const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545')
  // const network = await web3.eth.net.getNetworkType()
  const accounts = await web3.eth.getAccounts()
  this.account = accounts[0]
  this.artId = new web3.eth.Contract(ART_ID_ABI, ART_ID_ADDRESS)
  this.setState({
    account: accounts[0],
    artId: new web3.eth.Contract(ART_ID_ABI, ART_ID_ADDRESS),
  })
}
