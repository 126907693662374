import React, { useEffect, useState } from 'react'
import PieceOfArtItem from './PieceOfArtItem'
import { MDBCol, MDBIcon, MDBNavLink, MDBRow } from 'mdbreact'
import { getArtWorks } from './../blockchainUtils'
import Web3 from 'web3'

const PieceOfArtList = () => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(rows => {
    // Load the necessary stuff
    async function loadWeb3() {
      if (window.ethereum) {
        window.web3 = new Web3(window.ethereum)
        await window.ethereum.enable()
      } else if (window.web3) {
        window.web3 = new Web3(window.web3.currentProvider)
      } else {
        // DO NOTHING...
      }
    }

    async function loadBlockchainData() {
      setIsLoading(true)
      setRows(await getArtWorks())
      setIsLoading(false)
    }

    loadWeb3()
    loadBlockchainData()
  }, [])

  return (
    <>
      <MDBRow>
        <MDBCol md="9">
          <h1>Protected Art Works</h1>
        </MDBCol>
        <MDBCol md="3" className="text-right">
          <MDBNavLink className="btn btn-sm btn-green" exact to="/protect/new">
            <MDBIcon icon="plus" fas />
            <span> Add a new piece of art</span>
          </MDBNavLink>
        </MDBCol>
      </MDBRow>

      <p className="text-muted">
        Find below the Art Works that are protected. The data is safely written
        on the Blockchain and can not be changed except by the author itself or
        by a user that have management permissions on an Art Work.
      </p>

      {isLoading ? (
        <div>Loading</div>
      ) : (
        <div>
          {rows.map((artWork, i) => (
            <PieceOfArtItem key={i} artWork={artWork} />
          ))}
        </div>
      )}
    </>
  )
}

export default PieceOfArtList
