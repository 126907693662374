import React from 'react'
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdbreact'
import PieceOfArtList from '../components/PieceOfArtList'

class Protect extends React.Component {
  render() {
    return (
      <>
        <MDBEdgeHeader color="indigo darken-3" className="sectionPage" />
        <div className="mt-3 mb-5">
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md="10"
                className="mx-auto float-none white z-depth-1 py-2 px-2 pt-4"
              >
                <MDBContainer>
                  <PieceOfArtList />
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
        </div>
      </>
    )
  }
}

export default Protect
