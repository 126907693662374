import React from 'react'
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBCardTitle,
  MDBCol,
  MDBIcon,
  MDBNavLink,
  MDBRow,
} from 'mdbreact'

const PieceOfArtItem = props => {
  const { artWork } = props

  const metadata = artWork.metadata ? JSON.parse(artWork.metadata) : {}

  return (
    <MDBCard className="mb-4">
      <MDBCardBody>
        <MDBRow>
          <MDBCol md="8">
            <MDBCardTitle>{artWork.name}</MDBCardTitle>
          </MDBCol>
          <MDBCol md="4">
            <div className="d-flex justify-content-end">
              <MDBNavLink
                className="btn btn-sm "
                exact
                to={`/protect/${artWork.id}`}
                style={{ width: '60px' }}
              >
                <MDBIcon icon="pen" fas />
              </MDBNavLink>
            </div>
          </MDBCol>
        </MDBRow>
        <MDBCardText>{metadata.description}</MDBCardText>
      </MDBCardBody>
    </MDBCard>
  )
}

export default PieceOfArtItem
