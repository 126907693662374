import React, { Component } from 'react'
import { MDBFooter } from 'mdbreact'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './Routes'
import NavBar from './components/NavBar'

class App extends Component {
  render() {
    return (
      <Router>
        <div className="flyout">
          <NavBar />

          <main style={{ marginTop: '0rem' }}>
            <Routes />
          </main>
          <MDBFooter color="indigo">
            <p className="footer-copyright mb-0 py-3 text-center">
              &copy; {new Date().getFullYear()} Copyright
              <a href="https://becomart.ch"> Becomart</a>
            </p>
          </MDBFooter>
        </div>
      </Router>
    )
  }
}

export default App
