import React from 'react'
import { Route, Switch } from 'react-router-dom'

import HomePage from './pages/HomePage'
import Protect from './pages/Protect'
import ProtectNew from './pages/ProtectNew'
import SignIn from './pages/SignIn'

class Routes extends React.Component {
  render() {
    return (
      <Switch location={this.props.location}>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/protect" component={Protect} />
        <Route exact path="/protect/new" component={ProtectNew} />
        <Route exact path="/protect/:id" component={ProtectNew} />
        <Route exact path="/sign-in" component={SignIn} />

        <Route
          render={function() {
            return <h1>Not Found</h1>
          }}
        />
      </Switch>
    )
  }
}

export default Routes
