import React, { Component } from 'react'
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
  MDBTooltip,
  MDBIcon,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdbreact'
import { ReactComponent as Logo } from '../assets/logo.svg'

class NavBar extends Component {
  state = {
    collapseID: '',
  }

  toggleCollapse = collapseID => () =>
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : '',
    }))

  closeCollapse = collID => () => {
    const { collapseID } = this.state
    window.scrollTo(0, 0)
    collapseID === collID && this.setState({ collapseID: '' })
  }

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: 'transparent' }}
        onClick={this.toggleCollapse('mainNavbarCollapse')}
      />
    )

    const { collapseID } = this.state

    return (
      <>
        <MDBNavbar color="indigo" dark expand="md" fixed="top" scrolling>
          <Logo style={{ height: '2.5rem', width: '2.5rem' }} />
          <MDBNavbarBrand href="/" className="py-0 font-weight-bold">
            <strong className="align-middle">Art ID</strong>
          </MDBNavbarBrand>
          <MDBNavbarToggler
            onClick={this.toggleCollapse('mainNavbarCollapse')}
          />
          <MDBCollapse id="mainNavbarCollapse" isOpen={collapseID} navbar>
            <MDBNavbarNav left>
              <MDBNavItem>
                <MDBNavLink
                  exact
                  to="/"
                  onClick={this.closeCollapse('mainNavbarCollapse')}
                >
                  <strong>Home</strong>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  onClick={this.closeCollapse('mainNavbarCollapse')}
                  to="/protect"
                >
                  <strong>Protect</strong>
                </MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem className="mr-2">
                <MDBTooltip
                  placement="bottom"
                  domElement
                  style={{ display: 'block' }}
                >
                  <a
                    className="nav-link Ripple-parent"
                    href="https://becomart.ch/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>
                      <MDBIcon icon="question-circle" />
                    </strong>
                  </a>
                  <span>SUPPORT</span>
                </MDBTooltip>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <MDBIcon icon="user" />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu className="dropdown-default">
                    <MDBDropdownItem href="#!">Action</MDBDropdownItem>
                    <MDBDropdownItem href="#!">Another Action</MDBDropdownItem>
                    <MDBDropdownItem href="#!">
                      Something else here
                    </MDBDropdownItem>
                    <MDBDropdownItem href="#!">
                      Something else here
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
        {collapseID && overlay}
      </>
    )
  }
}

export default NavBar
