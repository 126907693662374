import React from 'react'
import {
  MDBEdgeHeader,
  MDBFreeBird,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBCardBody,
  MDBBtn,
  MDBAlert,
} from 'mdbreact'
import Web3 from 'web3'
import { Redirect } from 'react-router-dom'

class Protect extends React.Component {
  state = {
    account: '',
    redirect: null,
    metaMaskInstalled: false,
    postCount: 0,
    posts: [],
    loading: true,
  }

  constructor(props) {
    super(props)

    this.unlockWallet = this.unlockWallet.bind(this)
  }

  async componentWillMount() {
    // Detect Metamask
    const metaMaskInstalled = typeof window.web3 !== 'undefined'
    this.setState({ metaMaskInstalled })
    if (metaMaskInstalled) {
      await this.loadWeb3()
    }
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      // await window.ethereum.enable()
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      // DO NOTHING...
    }
  }

  async unlockWallet() {
    const web3 = window.web3
    await window.ethereum.enable()

    // Load account
    const accounts = await web3.eth.getAccounts()
    if (accounts.length > 0) {
      this.setState({
        redirect: '/protect',
      })
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        <MDBEdgeHeader color="indigo darken-3" className="sectionPage" />
        <div className="mt-3 mb-5">
          <MDBFreeBird>
            <MDBRow>
              <MDBCol
                md="10"
                className="mx-auto float-none white z-depth-1 py-2 px-2 pt-4"
              >
                <MDBContainer>
                  <MDBCardBody className="text-center">
                    {this.state.metaMaskInstalled ? (
                      <>
                        <h2 className="mb-3">Connect your Wallet</h2>
                        <div>
                          <span>It looks good. </span>
                          <i className="far fa-thumbs-up"></i>
                        </div>
                        <div className="mb-3">Web3 wallet was detected.</div>
                        <div className="mb-3">
                          Continue by unlocking your wallet.
                        </div>

                        {/*<MDBInput*/}
                        {/*  label="Type your password"*/}
                        {/*  icon="lock"*/}
                        {/*  group*/}
                        {/*  type="password"*/}
                        {/*  validate*/}
                        {/*/>*/}

                        <MDBBtn
                          color="primary"
                          onClick={this.unlockWallet}
                          disabled={!this.state.metaMaskInstalled}
                        >
                          Unlock your wallet
                        </MDBBtn>
                      </>
                    ) : (
                      <>
                        <MDBAlert color="danger">
                          <h2 className="mb-3">No wallet detected</h2>
                          Mmm... it seems you don't have a wallet installed in
                          your browser. Install a wallet by downloading the
                          MetaMask browser extension to further proceed and then
                          come back on this page. Things will look differently.
                        </MDBAlert>
                      </>
                    )}
                  </MDBCardBody>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
        </div>
      </>
    )
  }
}

export default Protect
